import { Box, Button, Divider, Typography } from '@mui/material'
import { useEffect, useState } from 'react';
import { ToastContainer, Zoom, cssTransition, toast } from 'react-toastify';
import { Navbar } from '../../Navbar/Navbar';
import React from 'react';
import './Home.css'
import { BottomNav } from '../../BottomNav/BottomNav';
import { ThemeColors } from '../../themes/mui';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { ConnectionStatus } from '../../models';

export const Toast_UpdateApp = (registration) => (
    <Box display={'flex'} justifyContent={'space-between'}>
        <Typography sx={{
            width: '70%',
            //marginTop:'-10px',
            //paddingTop:0,
            display: { xs: 'flex', md: 'flex' },
            fontFamily: 'monospace',
            fontWeight: 700,
            fontSize: '12px',
            //letterSpacing: '.3rem',
            color: ThemeColors.secondary,
            textDecoration: 'none',
        }} >{i18n.t('registerworker.new_version')}</Typography>
        <Divider sx={{ color: 'GrayText' }} orientation='vertical' />
        <Button sx={{ marginLeft: "10px", fontSize: '12px' }} fullWidth={true} size='small' variant='contained'
            onClick={() => { registration.waiting.postMessage({ type: 'SKIP_WAITING' });; window.location.reload() }}>Update</Button>
    </Box>
);
/*const Trans = cssTransition({
    enter: 'zoomIn',
    appendPosition: false,
    collapse: false,
  });*/

export interface HomeProps {
    home: ConnectionStatus,
    version: string,
    lng: string, 
    displayName: string,
    error: string
}

export const HomeNav = (props: HomeProps) => {
    console.log(`[HOMENav] :: init`)
    const navBarId = React.useRef<any>(null);
    const { t } = useTranslation();
    const [home, setHome] = useState<ConnectionStatus>();
    const [version, setVersion] = useState<string>();
    const [lng, setLng] = useState<string>();
    const [error, setError] = useState<string>();
    //let {home, version, lng} = props;

    useEffect(() => {
        console.log(`[HOMENav] :: useEffect []`)
        navBarId.current = toast(<Navbar error={props.error} home={props.home} version={`${props.version} - ${props.lng} ${props.displayName?`- ${props.displayName}`:""}`} />, { containerId:"Nav", position: 'top-center', draggable: false, autoClose: false, closeButton: false, transition: Zoom, style: { boxShadow: 'none', backgroundColor: ThemeColors.primary } });
        
        /*toast(<AdaptiveCard
            onExecuteAction={data=>{console.log(`AC onExecuteAction`, data)}}
            onActionSubmit={data=>{console.log(`AC onActionSubmit`, data)}}
            payload={card} hostConfig={hostConfig} />, {type:'info'})*/
        //card_Login({onExecuteAction:data=>{console.log(`Got onExecute data`, data)}})
    }, [])

    useEffect(() => {
        console.log(`[HOME] :: props changed`, props, home,version,lng)
        let changed = false;
        if (props.home != home) {
            setHome(props.home)
            changed = true
        }
        if (props.version != version) {
            setVersion(props.version)
            changed = true
        } 
        if (props.lng != lng) {
            setLng(props.lng)
            changed = true
        }
        if (props.error != error) {
            setError(props.error)
            changed = true
        }

        if (changed) {
            console.log(`[Home] :: updating navbar`, navBarId.current)
            toast.update(navBarId.current, { containerId:"Nav", render: <Navbar error={props.error} home={home} version={`${version} - ${lng} ${props.displayName?`- ${props.displayName}`:""}`} /> })
            console.log(`[HOME] :: Updated navbar`)
        }
    }, [props])

    return (
        <Box className="PageContainer">

        </Box>
    )
}

export const HomeFoot = () => {
    console.log(`[HOMEFOOT] :: init`)
    const footerId = React.useRef<any>(null);
    const { t } = useTranslation();

    useEffect(() => {
        console.log(`[HomeFoot] :: useEffect []`)
        //setTimeout(()=>{footerId.current = toast(<BottomNav />, {containerId:"Foot", draggable: false, autoClose: false, closeButton: false, transition: Zoom, style: { height: '40px', boxShadow: 'none', backgroundColor: ThemeColors.primary } });}, 0)
        //setTimeout(()=>{toast(t("welcome"), { type: 'info', containerId:"Foot" })}, 10000)
        
        /*toast(<AdaptiveCard
            onExecuteAction={data=>{console.log(`AC onExecuteAction`, data)}}
            onActionSubmit={data=>{console.log(`AC onActionSubmit`, data)}}
            payload={card} hostConfig={hostConfig} />, {type:'info'})*/
        //card_Login({onExecuteAction:data=>{console.log(`Got onExecute data`, data)}})
    }, [])

    return (
        <Box className="PageContainer">
            <BottomNav />
        </Box>
    )
}
