import React, { useEffect, useRef, useState } from 'react';
import theme from './themes/mui'
import './App.css';
import {Paper, ThemeProvider } from '@mui/material';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import {Toast_UpdateApp } from './views/Home/Home';
import { buildInfo } from './buildInfo';

import i18n from 'i18next';

import { socket, swReady } from '.';
import * as AC from "adaptivecards";
import { SubmitAction } from './cards/card-objects/SubmitAction';
import { ExecuteAction } from './cards/card-objects/ExecuteAction';
import { ShowCardAction } from './cards/card-objects/ShowCardAction';
import { TextInput } from './cards/card-objects/TextInput';
import { NumberInput } from './cards/card-objects/NumberInput';
import { ToggleInput } from './cards/card-objects/ToggleInput';
import { DateInput } from './cards/card-objects/DateInput';
import { ChoiceSetInput } from './cards/card-objects/ChoiceSetInput';
import { TextBlock } from './cards/card-objects/TextBlock';
import { showCard } from './cards/adaptive-card';

import lngPayload from './cards/templates/language-selection/card.json'
import lngData from './cards/templates/language-selection/data.json'
import userDataPayload from './cards/templates/user-data/card.json'
import userDataData from './cards/templates/user-data/data.json'

import { useTranslation } from 'react-i18next';
import { AuthData, ConnectionStatus, UserData } from './models';
import BottomAppBar from './views/FrontPage/frontpage';



export const configuration: serviceWorkerRegistration.Config = {
  onUpdate: (registration) => {
    if (registration && registration.waiting) {
      //registration.waiting.postMessage({ type: 'SKIP_WAITING' });
      toast.info(() => Toast_UpdateApp(registration), { containerId: "App", autoClose: false });
      /*if (window.confirm(i18n.t('registerworker.new_version'))) {
        registration.waiting.postMessage({ type: 'SKIP_WAITING' });
        window.location.reload();
      }*/
    }
  },
  onSuccess: (registration) => {
    if (registration) {

      if (window.confirm(i18n.t('registerworker.updated'))) {

      }
    }
  }
};

function App() {
  console.log(`[APP] :: init`)
  AC.GlobalRegistry.actions.register(SubmitAction.JsonTypeName, SubmitAction);
  AC.GlobalRegistry.actions.register(ExecuteAction.JsonTypeName, ExecuteAction);
  AC.GlobalRegistry.actions.register(ShowCardAction.JsonTypeName, ShowCardAction);
  AC.GlobalRegistry.elements.register(TextInput.JsonTypeName, TextInput);
  AC.GlobalRegistry.elements.register(NumberInput.JsonTypeName, NumberInput);
  AC.GlobalRegistry.elements.register(ToggleInput.JsonTypeName, ToggleInput);
  AC.GlobalRegistry.elements.register(DateInput.JsonTypeName, DateInput);
  AC.GlobalRegistry.elements.register(ChoiceSetInput.JsonTypeName, ChoiceSetInput);
  AC.GlobalRegistry.elements.register(TextBlock.JsonTypeName, TextBlock);

  //let skt = socket;
  const [isConnected, setIsConnected] = useState(socket.connected);
  const _wsError = useRef<string>("");
  const [wsError, setWsError] = useState<string>("");
  const [homeStatus, setHomeStatus] = useState<ConnectionStatus>(ConnectionStatus.OFF)
  const [displayName, setDisplayName] = useState("");
  const [uuid, setUuid] = useState<string>(() => {
    // getting stored value
    const saved = localStorage.getItem("uuid");
    const initialValue = (saved);
    return initialValue || "";
  });
  const { t, i18n } = useTranslation();
  const [lng, setLng] = useState<string>(() => {
    // getting stored value
    const saved = localStorage.getItem("lng");
    const initialValue = (saved);
    return initialValue || "en";
  });

  //console.log(`[APP] :: Registering serviceWorker`)
  //serviceWorkerRegistration.register(configuration);
  //const { notifications, clear, markAllAsRead, markAsRead, add, update, remove, find, sort, unreadCount } = useNotificationCenter();
  const onConnected = () => {
    console.log(`[APP] :: WS Connected`);
    _wsError.current = "";
    setIsConnected(true);
    setTimeout(() => { setHomeStatus(ConnectionStatus.ON) }, 0);
  }
  const onDisConnected = () => {
    console.log(`[APP] :: WS DisConnected`);
    _wsError.current = "";
    if (isConnected) {
      setIsConnected(false); setHomeStatus(ConnectionStatus.OFF);
      toast.clearWaitingQueue(); toast.dismiss({ containerId: "App" });
      toast("WeboHub heeft de connectie verbroken.", { containerId: "App" })
    }
  }
  const onWSError = (err) => {
    console.log(`WSerror`, err)
    _wsError.current = err.message;
    setWsError(_wsError.current)
    switch (err.message) {
      case "xhr poll error":
        console.log(`[APP] :: WS Errored with xhr poll error`);
        break;
      default:
        console.log(`[APP] :: WS uncaptured Errored`, err.message);
    }
  }


  const onAuth = (data: AuthData) => {
    console.log(`[AUTH] :: received data`, data);
    switch (data.type) {
      case 'REQUEST':
        if (!uuid) {
          //setUuid(data.userData.uuid)
          console.log(`[AUTH] :: no uuid,  Showing language card, current lng is `, i18n.resolvedLanguage)
          setLng(i18n.resolvedLanguage)
          showCard({
            payload: lngPayload, payloadData: lngData[i18n.resolvedLanguage], params: { containerId: "App" }, onExecuteAction: (cardData) => {
              console.log(`[AUTH] :: Got language key`, data)
              setLng(cardData._processedData.lstLanguage)
              i18n.changeLanguage(cardData._processedData.lstLanguage);
            }
          })
          showCard({
            payload: userDataPayload, payloadData: userDataData[i18n.resolvedLanguage], params: { containerId: "App" }, onExecuteAction: (cardData) => {
              console.log(`[AUTH] :: Got user data`, data)
              let res: any = cardData._processedData;
              let authData: AuthData = new AuthData();
              authData.type = 'RESPONSE'
              authData.userData = { ...data.userData, loginEmail: res.idLoginEmail, profile: res.idProfile, fName: res.idFirstName, lName: res.idLastName }
              console.log(`[AUTH] :: about to send authData response`, authData)
              setUuid(data.userData.uuid)
              socket.emit<"auth">("auth", authData)
            }
          })

        } else {
          console.log(`[AUTH] :: uuid known, returning uuid`)
          toast.info(t('authRequesting'), { containerId: "App" })
          let authData: AuthData = new AuthData();
          authData.type = 'RESPONSE'
          authData.userData = { ... new UserData(), uuid: uuid }
          socket.emit<"auth">("auth", authData)
        }
        break;
      case 'RESPONSE':
        console.log(`[AUTH] :: got auth response`, Object.keys(ConnectionStatus), data.userData)
        setDisplayName(`${data.userData.fName} ${data.userData.lName}`)
        if (Object.keys(ConnectionStatus).includes(data.userData.profile.toUpperCase() as ConnectionStatus)) {
          setHomeStatus(ConnectionStatus[data.userData.profile.toUpperCase()])
        } else { setHomeStatus(socket.connected ? ConnectionStatus.ON : ConnectionStatus.OFF) }
        console.log(`[AUTH] :: before welcome toast`)
        toast(t("welcome"), { type: 'info', containerId: "App" })
        break;
      case 'INVALID':
        toast.error(t('invalidAuth'), { containerId: "App" })
        setUuid("")
        break;
      default:
        console.log(`[AUTH] :: unknown Type ${data.type}`)
    }
  }


  useEffect(() => {
    //let skt = socket;
    if (socket.connected || swReady.observers.length > 0) return;
    const buildDate = new Date();
    console.log(`[APP] :: Build Number: ${buildInfo.buildVersion}`);
    console.log(`[APP] :: Build Date: ${buildDate.toString()}`);
    setTimeout(() => {
      console.log(`[APP] :: Connecting to WS ...`);
      socket.on('connect', onConnected);
      socket.on('disconnect', onDisConnected);
      socket.on('connect_error', onWSError);
      socket.on('auth', onAuth);
      try {
        socket.connect()
        console.log(`[APP] :: Listenign on engine errors`)
        socket.io.engine.on('error', (error) => {
          console.error('[APP] :: Engine.IO Error:', error);
        });
      } catch (err) { console.error('[APP] :: socket connect Error:', err); };
      console.log(`[APP] :: Connect to WS started`);
    }, 2000)

    /*swReady.subscribe(()=>{
      console.log(`[APP] :: Connecting to WS ...`);
      socket.on('connect', onConnected);
      socket.on('disconnect', onDisConnected);
      socket.on('auth', onAuth);
      socket.connect()
      console.log(`[APP] :: Connect to WS started`);
    })*/
    console.log(`[APP] :: Done`);

    return () => {
      console.log(`[APP] :: Closing App`);
      socket.off('connect', onConnected);
      socket.off('disconnect', onDisConnected);
      socket.off('connect_error', onWSError);
      socket.off('auth', onAuth);
      socket.disconnect();
    };
  }, [])


  useEffect(() => {
    //if (uuid=="") return
    localStorage.setItem('uuid', uuid)
    console.log(`[APP] :: localStorage uuid ${uuid}`)
  }, [uuid])

  useEffect(() => {
    //if (lng=="") return
    localStorage.setItem('lng', lng)
    console.log(`[APP] :: localStorage lng ${lng}`)
  }, [lng])

  /*useEffectOnce(() => {
    if (skt.connected) return;
    const buildDate = new Date();
    console.log(`[APP] :: Build Number: ${buildInfo.buildVersion}`);
    console.log(`[APP] :: Build Date: ${buildDate.toString()}`);
    swReady.subscribe(()=>{
      console.log(`[APP] :: Connecting to WS ...`);
      skt.on('connect', onConnected);
      skt.on('disconnect', onDisConnected);
      skt.on('auth', onAuth);
      skt.connect()
      console.log(`[APP] :: Connect to WS started`);
    })
    console.log(`[APP] :: Done`);
  })
    */



  return (
    <Paper sx={{backgroundColor:'white'}} square>
      {/*<ProvidesHostConfigContext hostConfig={hostConfig}></ProvidesHostConfigContext>*/}
      <ThemeProvider theme={theme}>
        <BottomAppBar isConnected={isConnected} home={homeStatus} version={buildInfo.buildVersion} lng={lng} displayName={displayName} error={wsError} />
        <ToastContainer containerId={"Nav"} position='top-center' />
        <ToastContainer style={{ marginTop: 70 }} containerId={"App"} position='top-center' toastStyle={{ boxShadow: 'none' }} />
      </ThemeProvider>

    </Paper>
  );
}

export default App;


//{/*<Navbar home={true} version={buildInfo.buildVersion}/>*/}
//{/*<BottomNav />*/}

{/*<CssBaseline />
<div className="AppContainer"> 
  <HomeNav home={homeStatus} version={buildInfo.buildVersion} lng={lng} displayName={displayName} error={wsError} />
  <HomeFoot />
  <ToastContainer containerId={"Nav"} position='top-center' />
  <ToastContainer style={{ marginTop: 70 }} containerId={"App"} position='top-center' toastStyle={{ boxShadow: 'none' }} />
</div>*/}