import Box from '@mui/material/Box'
import { useState } from 'react'


import styles from './BottomNav.module.css'
import { LinearProgress, Typography } from '@mui/material'
import { ThemeColors } from '../themes/mui'

export const BottomNav = () => (<
    Box justifyContent={'space-between'} width={'100%'} height={'100%'} sx={{
        marginTop: 0,
        paddingTop: 0, display: { xs: 'flex', md: 'flex' }
    }} flexDirection={'row'}>
    <Typography sx={{
        height: '100%',
        marginTop: '5px',
        paddingTop: 0,
        display: { xs: 'flex', md: 'flex' },
        fontFamily: 'monospace',
        fontWeight: 700,
        fontSize: '10px',
        letterSpacing: '.3rem',
        color: ThemeColors.secondary,
        textDecoration: 'none',
    }} >WEBO</Typography>
    <Typography sx={{
        height: '100%',
        marginTop: '5px',
        paddingTop: 0,
        display: { xs: 'flex', md: 'flex' },
        fontFamily: 'monospace',
        fontWeight: 700,
        fontSize: '10px',
        letterSpacing: '.3rem',
        color: ThemeColors.secondary,
        textDecoration: 'none',
    }} >HOMEHUB</Typography>
</Box>)


/*const BottomNav = () => {
    
    return (
        <Box className={styles.Container} >
            <LinearProgress color="secondary" />
        </Box>
    )
}*/


