// Core i18next library.
import i18n from "i18next";
// Bindings for React: allow components to
// re-render when language changes.
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

export const supportedLngs = {
  en: "English",
  nl: "Nederlands",
  fr: "Francais"
};


i18n
  // Add React bindings as a plugin.
  .use(initReactI18next)
  .use(LanguageDetector)
  // Initialize the i18next instance.
  .init({
    // Config options

    // Specifies the default language (locale) used
    // when a user visits our site for the first time.
    // We use English here, but feel free to use
    // whichever locale you want.                   
    //lng: "nl",

    // Fallback locale used when a translation is
    // missing in the active locale. Again, use your
    // preferred locale here. 
    fallbackLng: "nl",

    // Enables useful output in the browser’s
    // dev console.
    debug: true,
    supportedLngs: Object.keys(supportedLngs),
    // Normally, we want `escapeValue: true` as it
    // ensures that i18next escapes any code in
    // translation messages, safeguarding against
    // XSS (cross-site scripting) attacks. However,
    // React does this escaping itself, so we turn 
    // it off in i18next.
    interpolation: {
      escapeValue: false,
    },

    // Translation messages. Add any languages
    // you want here.
    resources: {
      // English
      en: {
        translation: {
          registerworker: {
            new_version: "New version is available; please press 'Update' or restart the app.",
            updated: "New version is installed."
          },
          welcome: "Welcome to Webo HomeHub!",
          authInvalid:"You are not allowed to Webo HomeHub",
          authRequesting:"We're verifying you ..."
        },
      },
      // Dutch
      nl: {
        translation: {
          registerworker: {
            new_version: "Nieuwe versie beschikbaar, klik 'Update' of herstart de app",
            updated: "De nieuwe versie is geinstalleerd."
          },
          welcome: "Welkom bij Webo HomeHub!",
          invalidAuth:"U werd niet toegelaten tot Webo HomeHub",
          authRequesting:"We verifieren u ..."
        },
      },
    },
  });

export default i18n;