import * as React from 'react';
import { styled, ThemeProvider } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Fab from '@mui/material/Fab';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Avatar from '@mui/material/Avatar';
import { RoomServiceTwoTone } from '@mui/icons-material';
import { BottomNav } from '../../BottomNav/BottomNav';
import { Navbar } from '../../Navbar/Navbar';
import { ConnectionStatus } from '../../models';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import theme, { ThemeColors } from '../../themes/mui'

export interface HomeProps {
    home: ConnectionStatus,
    version: string,
    lng: string,
    displayName: string,
    error: string,
    isConnected: boolean
}

const messages = [
    {
        id: 1,
        primary: 'Brunch this week?',
        secondary: "I'll be in the neighbourhood this week. Let's grab a bite to eat",
        person: '/static/images/avatar/5.jpg',
    },
    {
        id: 2,
        primary: 'Birthday Gift',
        secondary: `Do you have a suggestion for a good present for John on his work
      anniversary. I am really confused & would love your thoughts on it.`,
        person: '/static/images/avatar/1.jpg',
    },
    {
        id: 3,
        primary: 'Recipe to try',
        secondary: 'I am try out this new BBQ recipe, I think this might be amazing',
        person: '/static/images/avatar/2.jpg',
    },
    {
        id: 4,
        primary: 'Yes!',
        secondary: 'I have the tickets to the ReactConf for this year.',
        person: '/static/images/avatar/3.jpg',
    },
    {
        id: 5,
        primary: "Doctor's Appointment",
        secondary: 'My appointment for the doctor was rescheduled for next Saturday.',
        person: '/static/images/avatar/4.jpg',
    },
    {
        id: 6,
        primary: 'Discussion',
        secondary: `Menus that are generated by the bottom app bar (such as a bottom
      navigation drawer or overflow menu) open as bottom sheets at a higher elevation
      than the bar.`,
        person: '/static/images/avatar/5.jpg',
    },
    {
        id: 7,
        primary: 'Summer BBQ',
        secondary: `Who wants to have a cookout this weekend? I just got some furniture
      for my backyard and would love to fire up the grill.`,
        person: '/static/images/avatar/1.jpg',
    },
    {
        id: 11,
        primary: 'Brunch this week?',
        secondary: "I'll be in the neighbourhood this week. Let's grab a bite to eat",
        person: '/static/images/avatar/5.jpg',
    },
    {
        id: 12,
        primary: 'Birthday Gift',
        secondary: `Do you have a suggestion for a good present for John on his work
      anniversary. I am really confused & would love your thoughts on it.`,
        person: '/static/images/avatar/1.jpg',
    },
    {
        id: 13,
        primary: 'Recipe to try',
        secondary: 'I am try out this new BBQ recipe, I think this might be amazing',
        person: '/static/images/avatar/2.jpg',
    },
    {
        id: 14,
        primary: 'Yes!',
        secondary: 'I have the tickets to the ReactConf for this year.',
        person: '/static/images/avatar/3.jpg',
    },
    {
        id: 15,
        primary: "Doctor's Appointment",
        secondary: 'My appointment for the doctor was rescheduled for next Saturday.',
        person: '/static/images/avatar/4.jpg',
    },
    {
        id: 16,
        primary: 'Discussion',
        secondary: `Menus that are generated by the bottom app bar (such as a bottom
      navigation drawer or overflow menu) open as bottom sheets at a higher elevation
      than the bar.`,
        person: '/static/images/avatar/5.jpg',
    },
    {
        id: 17,
        primary: 'Summer BBQ',
        secondary: `Who wants to have a cookout this weekend? I just got some furniture
      for my backyard and would love to fire up the grill.`,
        person: '/static/images/avatar/1.jpg',
    },
];

const StyledFab = styled(Fab)({
    position: 'absolute',
    zIndex: 1,
    top: -40,
    left: 0,
    right: 0,
    margin: '0 auto'
});

export default function BottomAppBar(props: HomeProps) {
    const { t } = useTranslation();
    const [home, setHome] = useState<ConnectionStatus>();
    const [version, setVersion] = useState<string>();
    const [lng, setLng] = useState<string>();
    const [error, setError] = useState<string>();
    const [isConnected, setIsConnected] = useState<boolean>(false);

    useEffect(() => {
        console.log(`[HOME] :: props changed`, props, home, version, lng)
        let changed = false;
        if (props.home != home) {
            setHome(props.home)
            changed = true
        }
        if (props.version != version) {
            setVersion(props.version)
            changed = true
        }
        if (props.lng != lng) {
            setLng(props.lng)
            changed = true
        }
        if (props.error != error) {
            setError(props.error)
            changed = true
        }

        if (changed) {
            console.log(`[Home] :: updating navbar`)
            //toast.update(navBarId.current, { containerId:"Nav", render: <Navbar error={props.error} home={home} version={`${version} - ${lng} ${props.displayName?`- ${props.displayName}`:""}`} /> })
            console.log(`[HOME] :: Updated navbar`)
        }
    }, [props])
    return (
        <ThemeProvider theme={theme}>
            <React.Fragment>
                <Box display={'flex'} flexDirection={'column'} sx={{ pb: '50px' }}>

                    <AppBar elevation={0} position="fixed" sx={{ backgroundColor: ThemeColors.primary, height: '50px' }}>
                        <Toolbar sx={{ height: '500px' }} >
                            <Navbar error={props.error} home={home} version={`${version} - ${lng} ${props.displayName ? `- ${props.displayName}` : ""}`} />
                        </Toolbar>
                    </AppBar>

                    {(isConnected || false) ? <Box marginTop={'30px'}>
                        {<List sx={{ mb: 2 }}  >
                            {messages.map(({ id, primary, secondary, person }) => (
                                <React.Fragment key={id}>
                                    {(id === 1) && (
                                        <ListSubheader sx={{ bgcolor: 'background.paper' }}>
                                            Today
                                        </ListSubheader>
                                    )}
                                    {(id === 5) && (
                                        <ListSubheader sx={{ bgcolor: 'background.paper' }}>
                                            Yesterday
                                        </ListSubheader>
                                    )}
                                    <ListItemButton>
                                        <ListItemAvatar>
                                            <Avatar alt="Profile Picture" src={person} />
                                        </ListItemAvatar>
                                        <ListItemText primary={primary} secondary={secondary} />
                                    </ListItemButton>
                                </React.Fragment>
                            ))}
                        </List>}
                    </Box> :
                        <></>}
                </Box>
                <AppBar position="fixed" sx={{ width: '100%', height: '30px', top: 'auto', bottom: 0, backgroundColor: ThemeColors.primary }}>
                    <Toolbar>
                        <BottomNav />
                        <StyledFab color="secondary" aria-label="add">
                            <RoomServiceTwoTone />
                        </StyledFab>
                    </Toolbar>
                </AppBar>
            </React.Fragment>
        </ThemeProvider>
    );
}
