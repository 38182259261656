export class UserData {
    uuid: string
    loginEmail: string
    fName: string
    lName: string
    profile: 'admin'|'user'|'guest'|'none' = 'none'
}
export class AuthData {
    type: 'REQUEST' | 'RESPONSE' | 'INVALID'
    userData: UserData
}
export enum ConnectionStatus {ADMIN='info', USER='success', GUEST='warning', OFF='error', ON='white'}