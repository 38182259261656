import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App, { configuration } from './App';
import reportWebVitals from './reportWebVitals';
import "./i18n/config.ts";
import 'react-toastify/dist/ReactToastify.css';
import { io } from 'socket.io-client';
import { ToastContainer } from 'react-toastify';
import { ReplaySubject } from 'rxjs'
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

//const URL = process.env.NODE_ENV === 'production' ? 'http://localhost:3001' : 'http://localhost:3001';
const URL = process.env.NODE_ENV === 'production' ? 'https://webostar.local:3001' : 'https://webostar.local:3001';
export const socket = io(URL, {
  autoConnect: false,
  reconnectionAttempts: 3,
  timeout: 3000,
  rejectUnauthorized: false,
  //transports:['websocket']
});

export const swReady: ReplaySubject<boolean> = new ReplaySubject()

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

console.log(`[INDEX] :: loading ...`)

root.render(
  <React.StrictMode>

    <App />
    <ToastContainer style={{ top: "50%" }} containerId={"SW"} position='top-center' toastStyle={{ boxShadow: 'none' }} />
    <ToastContainer style={{ marginBottom: 70 }}containerId={"Foot"} newestOnTop={true} position='bottom-center' />
  </React.StrictMode>
);
console.log(`[INDEX] :: loading done`)
serviceWorkerRegistration.register(configuration);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
//serviceWorkerRegistration.register(toast);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
