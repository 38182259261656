import React from "react";
import * as ReactDOM from "react-dom/client";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../../themes/mui";

const ThemedElement = (element: React.ReactElement) => {
  return <ThemeProvider theme={theme}>{element}</ThemeProvider>;
};

export const reactDomRender = (
  reactElement: React.ReactElement
): HTMLElement | undefined => {
  const div = document.createElement("div");
  const themedElement = ThemedElement(reactElement);
  const root = ReactDOM.createRoot(div);
  root.render(themedElement);
  return div;
};
