import * as React from "react";
import * as MuiTextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import styled from "styled-components";
import { FormControl } from "@mui/material";

const TextField = (props) => {
  const { size = "small", variant = "outlined", onChange } = props;
  const [error, setError] = React.useState(false);
  //console.log("PROPS UPDATED");


  
  const handleChange = (event) => {
    const newValue = event.target.value;
    if (newValue=="") {setError(true)} else {setError(false)}
    if (onChange) onChange(newValue);
  };

  return (
    <Box sx={{ marginTop: "10px" }}>
      <FormControl fullWidth size={size}>
      <MuiTextField.default
        {...props}
        //error={error}
        size={size}
        //helperText={error?props.errorMessage:""}
        variant={variant}
        onChange={handleChange}
        onBlur={handleChange}
      />
      </FormControl>
    </Box>
  );
};

export default styled(TextField)`
  width: 100%;
`;
